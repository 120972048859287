import React, { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import './GoogleSheetModal.css';
import api from '../../api';
import { toast } from 'react-toastify';

const GoogleSheetModal = (props) => {
  const {
    handleClose,
    show,
    handleWriteReportSelected,
    sheetDetails,
    loadingButton,
    width,
    unlinkGoogleSheet,
  } = props;

  const [isLoadingGoogleDriveApi, setIsLoadingGoogleDriveApi] = useState(false);
  const [isFetchingGoogleDriveFiles, setIsFetchingGoogleDriveFiles] =
    useState(false);
  const [listDocumentsVisibility, setListDocumentsVisibility] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [dataListSource, setDataList] = useState([]);
  const [sheetName, setSheetName] = useState('');
  const [selectedSheet, setSelectedSheet] = useState('');
  const [tabs, setTabs] = useState([]);
  const [dataSheets, setDataSheets] = useState([]);
  const [tabIndex, setTabIndex] = useState('');
  const [sheetData, setSheetData] = useState(null);
  const [sheetLoading, setSheetLoading] = useState(false);
  const [googleError, setGoogleError] = useState('');
  const [checkTokenLoading, setCheckTokenLoading] = useState(false);
  // console.log('google');
  const handleSheet = async (data, tabIdx) => {
    setSheetLoading(true);
    const sheetID = data;
    let list = [];
    try {
      let sheet = await api.get(`/google/sheet?id=${sheetID}`);

      const { data } = sheet;
      setDataSheets(data.sheets);
      if (sheet && tabIdx) {
        api
          .post('/google/sheet_get_batch', {
            spreadsheetId: sheetID,
            ranges: tabIdx?.split('!')?.[0],
            majorDimension: 'COLUMNS',
          })
          .then((dataR) => {
            const data2 = dataR.data;
            const ranges = JSON.parse(
              JSON.stringify(data2?.valueRanges[0]?.range),
            );

            let m = data2?.valueRanges[0]?.range?.split('!');

            const body = {
              name: m[0].replace(/["']/g, ''),
              rows: data2?.valueRanges[0]?.values,
              range: ranges,
            };
            list.push(body);
            setTabs(list);
            setSheetLoading(false);
          });
        } else {
          setSheetLoading(false);
        }
    } catch (e) {
      setSheetLoading(false);
      console.log('Err:', e);
    }
  };

  useEffect(() => {
    if (show) {
      setGoogleError('');
      check_token();
    }
  }, [show]);

  const googleAuth = async () => {
    const id = JSON.parse(localStorage.getItem('user'))?._id;

    let res = await api.get(`/authorize?userId=${id}`);

    window.open(res?.data?.url, 'Google OAuth', 'width=500,height=600');
    handleClose();
    // setPop(popup);
    // popup.close();
  };

  const check_token = async () => {
    setCheckTokenLoading(true);
    const id = JSON.parse(localStorage.getItem('user'))?._id;

    try {
      let res = await api.get(`/google/check_token?userId=${id}`);
      if (res?.status === 204) {
        console.log('not found_res_', res);
        googleAuth();
      } else if (res?.status === 200) {
        //list sheets data
        setDocuments(res.data);
      }
      setCheckTokenLoading(false);
    } catch (e) {
      setCheckTokenLoading(false);
      console.log('Err:', e.response.data.errors?.[0]?.message);
      if ('Insufficient Permission' === e.response.data.errors?.[0]?.message) {
        const x =
          'Insufficient Permission, Reauthenticate with google drive and sheet permissions';
        setGoogleError(x);
        toast(x, { type: 'error' });
        let userId = JSON.parse(localStorage.getItem('user'))?._id;
        api.post('/google/disconnectGoogle', { userId: userId }).then((res) => {
          if (res?.data?.message === 'Logged out') {
            unlinkGoogleSheet('disconnectGoogle');
          }
        });
      }
    }
  };

  useEffect(() => {
    if (documents?.length > 0) {
      let list = [];
      documents.forEach((val) => {
        const body = {
          name: val.name,
          id: val.id,
        };
        list.push(body);
      });

      setDataList(list);
    }
  }, [documents]);

  useEffect(() => {
    if (sheetDetails !== null && dataListSource?.length > 0) {
      setSheetName(sheetDetails?.selectedSheet?.id);
      setSelectedSheet(sheetDetails?.selectedSheet);

      handleSheet(sheetDetails?.selectedSheet?.id, sheetDetails?.tab);
    }
    if (sheetDetails === null || dataListSource?.length === 0) {
      setSheetLoading(false)
      setSheetName('');
      setSelectedSheet('');
      setTabIndex('');
      setTabs([]);
    }
  }, [dataListSource, sheetDetails]);

  useEffect(() => {
    if (tabs?.length > 0 && sheetDetails !== null) {
      setTabIndex(sheetDetails?.tab?.split('!')?.[0]);
      const body = {
        selectedSheet,
        tab: sheetDetails?.tab,
      };
      setSheetData(body);
    }
  }, [tabs, sheetDetails, tabIndex]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Modal.Title
          className="text-center"
          style={{ color: '#4AA181', fontSize: '40px' }}
        >
          Connect Google Sheet
        </Modal.Title>
        <p
          className="reload_modal_body_para mt-3 mb-4"
          style={{
            color: '#92929D',
            fontSize: '24px',
          }}
        >
          <p>
            The drop-down selector may not appear if popups are blocked in your
            browser.
          </p>
          <p>
            If popups are not blocked and you are using Google Chrome, your
            company's policy probably blocks the connection.
          </p>
          {/* <p>
            If the Sheet is protected you should manually add email{' '}
            <strong>dt-dev@datatera-oauth.iam.gserviceaccount.com</strong> as an
            editor.
          </p> */}
        </p>
        {sheetLoading || checkTokenLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner
              size="md"
              animation="border"
              variant="secondary"
              className="m-2 "
            />
          </div>
        ) : googleError ? (
          <p style={{color:"red"}}>{googleError}</p>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center">
            {!isLoadingGoogleDriveApi &&
              dataListSource?.length > 0 &&
              !isFetchingGoogleDriveFiles && (
                <select
                  name="sheetName"
                  id="sheetName"
                  className="select-input2"
                  value={sheetName}
                  onChange={(e) => {
                    if (e.target.value !== 'Select Spreadsheet') {
                      const data = dataListSource?.find(
                        (val) => val.id === e.target.value,
                      );
                      console.log('data-==-=-=1233----',data)
                      setSelectedSheet(data);
                      handleSheet(e.target.value);
                      setSheetName(e.target.value);
                    } else if (e.target.value === 'Select Spreadsheet') {
                      setSheetName(e.target.value);
                      setSelectedSheet('');
                      setTabs([]);
                    }
                  }}
                >
                  <option value="Select Spreadsheet">Select Spreadsheet</option>

                  {dataListSource?.map((val, i) => (
                    <option value={val.id} key={i}>
                      {val?.name}
                    </option>
                  ))}
                </select>
              )}
            {isLoadingGoogleDriveApi ||
              (isFetchingGoogleDriveFiles && (
                <Spinner animation="border" variant="secondary" />
              ))}
            {!isLoadingGoogleDriveApi &&
              !isFetchingGoogleDriveFiles &&
              dataListSource?.length > 0 && sheetName &&  (
                <select
                  name="sheetName"
                  id="sheetName"
                  className="select-input2 mt-3 "
                  value={tabIndex}
                  onChange={(e) => {
                    if (e.target.value !== 'Select Sheet') {
                      const body = {
                        selectedSheet,
                        tab: e.target.value,
                      };
                      setTabIndex(e.target.value);
                      setSheetData(body);
                    } else if (e.target.value === 'Select Sheet') {
                      setTabIndex(-1);
                      setSheetData(null);
                    }
                  }}
                >
                  <option value="Select Sheet">Select Sheet</option>

                  {dataSheets?.map((val, c) => (
                    <option value={val?.properties?.title} key={c}>
                      {val?.properties?.title}
                    </option>
                  ))}
                </select>
              )}
            {/* <Button
                variant="dark"
                onClick={() => {
                  if (tabIndex !== "" && sheetData !== null) {
                    const data = tabs?.find((val) => val?.range === tabIndex);
                    if (data) {
                      handleWriteReportSelected(data?.rows, sheetData);
                    }
                  }
                }}
                className="mt-5"
              >
                Connect Now
              </Button> */}

            <div className="d-flex justify-content-around gap-3 mt-5">
              {sheetDetails && (
                <Button
                  variant="secondary"
                  className={`downloading_img btn-light w-50`}
                  onClick={unlinkGoogleSheet}
                >
                  Disconnect Google Sheet
                </Button>
              )}
              <Button
                variant="dark"
                onClick={() => {
                  if (tabIndex !== '' && sheetData !== null) {
                    const data = dataSheets?.find((val) => val?.properties?.title === tabIndex);
                    if (data) {
                      handleWriteReportSelected(sheetData);
                    }
                  }
                }}
              >
                Connect Now
              </Button>
            </div>
            <p
              className="text-danger ms-auto"
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                fontSize: '14px',
                marginRight: '38px',
              }}
              onClick={() => {
                let userId = JSON.parse(localStorage.getItem('user'))?._id;
                api
                  .post('/google/disconnectGoogle', { userId: userId })
                  .then((res) => {
                    if (res?.data?.message === 'Logged out') {
                      unlinkGoogleSheet('disconnectGoogle');
                    }
                  });
              }}
            >
              Disconnect Google Account
            </p>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default GoogleSheetModal;
