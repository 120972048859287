import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import plus_icon from '../assets/images/plus_icon.png';
import Drawer from 'react-modern-drawer';
import Chat_icon from '../assets/images/Chat_icon.png';
import close from '../assets/images/close.png';
import updates_icon from '../assets/images/updates_icon.png';
import extension_icon from '../assets/images/extension_icon.webp';
import logo from '../assets/images/Datatera.svg';
import subscription_icon from '../assets/images/subscribe_white.svg';
import upgrade_icon from '../assets/images/plus.png';
import Joyride from 'react-joyride';
import logout_icon from '../assets/images/logout_icon.png';
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineSetting,
  AiOutlineEllipsis,
  AiOutlineCopy,
  AiOutlineBell,
  AiOutlineMail,
} from 'react-icons/ai';
import { HiOutlineBellAlert, HiOutlineBellSlash } from 'react-icons/hi2';
import catchAsync from '../utiles/catchAsync';
import { Formik } from 'formik';
import * as yup from 'yup';
import api from '../api/index';
import {
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  Form,
  Spinner,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AuthContext } from '../context/auth';
import { ListContext } from '../context/list';
import useWindowDimensions from '../utiles/getWindowDimensions';
import './sideBar.css';
import { plans } from '../service/plan';
import PricingModal from './PricingModal';
import { PlansContext } from '../context/plans/plans';
import * as XLSX from 'xlsx';
import moment from 'moment';
import Select from 'react-dropdown-select';

const Sidebar = ({ openPlan, setOpenPlan, handleUploadFile }) => {
  const [demoId, setDemoId] = useState(-1);
  const [dropdownPosition, setDropdownPosition] = useState({x: null, y: null});
  const [dropDownValue, setDropDownValue] = useState(null);
  const [dataPresentCheck, setDataPresentCheck] = useState('');
  const notesRef = useRef(null);

  //for creating conversion with templateId and Link
  const [selectConversion, setSelectConversion] = useState(false);
  const [selectedConversion, setSelectedConversion] = useState([
    {
      value: '',
      label: 'Select',
    },
  ]);
  const [conversionToList, setConversionToList] = useState([]);
  const [runOnce, setRunOnce] = useState(true);
  const [processUrlProp, setProcessUrlProp] = useState('');

  useEffect(() => {
    if (openPlan) {
      handlePriceModalShow();
    }
  }, [openPlan]);

//   useEffect(() => {
//     // console.log("dropdownPosition.top - notesRef.current.scrollTop", dropdownPosition.y - notesRef.current.scrollTop)
//   }, [dropdownPosition, notesRef])
  

  const deletedemo = catchAsync(async (id) => {
    try {
      let res = await api.delete(`/conversion/${id}`);
      let currCons = JSON.parse(localStorage.getItem('currentConverstion'));
      if (currCons === id) {
        localStorage.removeItem('currentConverstion');
        setListItems(null);
      }
      const data = conversions.filter((elem) => {
        return elem._id !== id;
      });
      setConversions(data);
      if (data?.length === 0) {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete('id');
        navigate('/?' + queryParams.toString());
      } else {
        handleButtonClick(conversions[0]._id);
        setListItems(conversions[0]._id);
      }

      setFirstCheckLocation(false);
    } catch (error) {}
  });

  const demo = catchAsync(async (values) => {
    try {
      let userId = JSON.parse(localStorage.getItem('user'))?._id;
      values.user = userId;
      let res = await api.post('/conversion', values);
      console.log(res);
      setConversions([...conversions, res.data.createConversion]);
      setListItems(res.data.createConversion?._id);

      setListItems(res.data.createConversion?._id);
      setDemoId(res.data.createConversion?._id);
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete('id');
      queryParams.append('id', res.data.createConversion?._id);
      navigate('/?' + queryParams.toString());
    } catch (error) {
      console.log(error);
    }
  });
  const onJoyrideCallback = (data) => {
    if (data.index === 0 && data.lifecycle === 'tooltip') {
      console.log('Callback triggered for step:', data.index);
      let name = 'demo';
      let val = { name: name };
      demo(val);
    } else if (data.index === 4 && data.lifecycle === 'complete') {
      // deletedemo(demoId);
    }
  };

  const steps = [
    {
      index: 0,
      action: 'start',
      TOOLTIP: 'tooltip',
      status: 'running',
      type: 'step:before',
      target: '#newstepone',
      placementBeacon: 'left',

      content: <p>Create a new Transformation to start</p>,
    },

    {
      index: 1,
      target: '.step-2',
      // type: 'step:before',
      content: (
        <p>Daily remaining operations left according to your subscription</p>
      ),
    },
    {
      index: 2,
      target: '#joyonestep3',
      // type: 'step:before',
      content: (
        <p>
          Step 1 is aimed to feed the AI with the sample of data that you want
          to collect. It should be just several rows of data from 2 to 5 rows,
          not less, not more. You can upload this sample from a CSV file by
          hitting this button
        </p>
      ),
    },
    {
      index: 3,
      target: '#joyonestep4',
      type: 'step:before',
      content: (
        <p>
          Step 1 is aimed to feed the AI with the sample of data that you want
          to collect. It should be just several rows of data from 2 to 5 rows,
          not less, not more. You can upload this sample from a CSV file by
          hitting this button
        </p>
      ),
    },
    {
      index: 4,
      target: '#joyonestep5',
      type: 'step:before',
      content: (
        <p>
          You can select from predefined templates for HubSpot, LinkedIn,
          Amazon, ProductHunt, and other sources and destinations of data.
          Templates cover such areas as leads and investors for the CRM,
          information about consumer and IT products, and other things.
        </p>
      ),
    },
  ];

  const [userPlan, setUserPlan] = useState();

  const getUserPlan = async () => {
    await api.get(`/user/me`).then((res) => {
      setUserPlan(res.data?.subscriptions);
    });
  };

  const {
    list,
    setListItems,
    openSideBar,
    setOpenSideBar,
    fetchConversions,
    setFetchConversions,
  } = useContext(ListContext);

  const { height, width } = useWindowDimensions();

  const { isLogin, signOut, userDetails, setUserDetails } =
    useContext(AuthContext);
  const [isOpen, setIsOpen] = React.useState(true);
  const [updateConversion, setupdateConversion] = useState(null);
  const [run, setRun] = useState(false);

  const [loadingTemplate, setLoadingTemplate] = React.useState(null);
  const [param, setParams] = React.useState(null);
  const [firstCheckLocation, setFirstCheckLocation] = React.useState(true);

  //
  const handleButtonClick = (_id) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete('id');
    queryParams.append('id', _id);

    navigate('/?' + queryParams.toString());
  };

  const location = useLocation();

  const handleButtonClickget = () => {
    const searchParams = new URLSearchParams(location.search);
    const paramValue = searchParams.get('id');
    setParams(paramValue);
  };
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const [show, setShow] = useState(false);
  const [showPriceModal, setPriceModalShow] = useState(false);
  const [conversions, setConversions] = useState();
  const navigate = useNavigate();
  const schema = yup.object().shape({
    name: yup.string().required('Transformation Name.'),
    description: yup.string(),
  });
  const readFile = async (temp, id, templateId, additionalData) => {
    const file = new File([temp?.data], '', {
      type: 'text/csv',
    });
    const reader = new FileReader();

    reader.onload = async (e) => {
      const bstr = e.target.result;
      const workbook = XLSX.read(bstr, { type: 'array', raw: true });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      let dupData1 = jsonData?.filter((el) => el?.length > 0);
      let dupData = JSON.parse(JSON.stringify(dupData1));
      if (dupData?.length > 0) {
        let userId = JSON.parse(localStorage.getItem('user'))?._id;
        const head = JSON.parse(JSON.stringify(dupData[0]));

        let values = {
          user: userId,
          data: [{ tableHeaders: head, tableData: dupData }],
          csvFileName: temp?.name,
          csvFileSize: temp?.size,
          conversion: id ? id : list,
          sheetDetailsWrite: { empty: '' },
          templateId: templateId,
          ...additionalData,
        };
        try {
          await api.post('/conversion/addData', values).then(async (res) => {
            // Delete the item from localStorage
            localStorage.removeItem('queryParams');

            const queryParams = new URLSearchParams(window.location.search);
            queryParams.delete('id');
            queryParams.append('id', id);
            // navigate('/?' + queryParams.toString());
            // window.location.reload();
            let userId = JSON.parse(localStorage.getItem('user'))?._id;

            let res2 = await api.get(`/conversion/all-notes/${userId}`);
            // console.log(res.data)
            setConversions(res2.data.getAllConversion);
            setListItems(id);
            handleButtonClick(id);
            fetchEmailSubscriptions(id);
            setLoadingTemplate(false);
          });
        } catch (error) {
          setLoadingTemplate(false);
          localStorage.removeItem('queryParams');

          console.log(error);
        }
      }
    };

    reader.readAsArrayBuffer(file);
  };
  const grabData = (value) => {
    const {
      Pagination,
      merge,
      model,
      pagination_max_iteractions,
      processUrls,
      entireWebsite,
    } = value ?? {};
    return {
      Pagination,
      merge,
      model,
      pagination_max_iteractions,
      processUrls,
      entireWebsite,
    };
  };
  const handleShow = () => {
    //commented because this case is handled on new-conversion screen
    // if (location?.pathname === '/new-conversion') {
    //   navigate('/');
    // }
    setShow(true);
  };
  const handlePriceModalShow = () => setPriceModalShow(true);
  const addTempToConversion = async (res, templateId) => {
    console.log('res, templateId_', res, templateId);
    if (res.data?.createConversion?.addTemplate) {
      await readFile(
        res?.data?.createConversion?.addTemplate,
        res.data?.createConversion?._id,
        templateId,
        grabData(res?.data?.createConversion?.addTemplate),
      );
    } else {
      if (res.data?.createConversion) {
        setConversions([...conversions, res.data?.createConversion]);
      }
      setListItems(res.data?.createConversion?._id || res?._id);
      const queryParams = new URLSearchParams(window.location.search);
      localStorage.removeItem('queryParams');
      queryParams.delete('id');
      queryParams.append('id', res.data?.createConversion?._id || res?._id);
      navigate('/?' + queryParams.toString());
      setLoadingTemplate(false);
      setSelectConversion(false);
    }
  };
  const handleSubmit = catchAsync(async (values, resetForm) => {
    setLoadingTemplate(true);
    if (conversions && updateConversion === null) {
      let userId = JSON.parse(localStorage.getItem('user'))?._id;
      values.user = userId;

      const storedQueryParams = localStorage.getItem('queryParams');
      const tempQuery = new URLSearchParams(storedQueryParams);
      if (storedQueryParams) {
        values.templateId = tempQuery.get('templateid');
        values.url = tempQuery.get('url');
      }
      // setListItems(res.data.createConversion?._id);
      // handleButtonClick(res.data.createConversion?._id);
      const convs = conversions?.filter(
        (conversion) =>
          values.templateId &&
          conversion?.templateId &&
          conversion?.templateId === values.templateId,
      );
      if (values?.url) {
        const file = new File([values?.url], 'text.txt', {
          type: 'text/plain',
        });
        if (convs?.length === 0) {
          let res = await api.post('/conversion', values);
          await addTempToConversion(res, values.templateId);
          await handleUploadFile(
            file,
            '0',
            null,
            '1',
            true,
            true,
            res.data.createConversion?._id,
            true,
            true,
          );
        } else if (convs?.length === 1) {
          await handleUploadFile(
            file,
            '0',
            null,
            '1',
            true,
            true,
            convs?.[0]?._id,
            true,
            true,
          );
          addTempToConversion(convs?.[0], values.templateId);
        } else {
          setConversionToList(convs);
          setProcessUrlProp(values.url);
          setLoadingTemplate(false);
          //show modal
          setSelectConversion(true);
        }
      } else {
        let res = await api.post('/conversion', values);
        addTempToConversion(res, values.templateId);
      }
    } else {
      let res = await api.patch(`/conversion/${updateConversion._id}`, values);

      setConversions(
        conversions.map((elem) => {
          if (elem._id == res.data.data._id) {
            return (elem = res.data.data);
          } else {
            return elem;
          }
        }),
      );
      setLoadingTemplate(false);
    }
    handleButtonClickget?.();
    setFirstCheckLocation(false);
    resetForm?.();
    handleClose?.();
  }, toast);

  const openConversionModal = catchAsync(async (user) => {
    setupdateConversion(user);
    setShow(true);
  });

  const handleClose = () => {
    setupdateConversion(null);
    setShow(false);
  };

  const handlePriceModalClose = () => {
    setPriceModalShow(false);
    setOpenPlan?.(false);
  };

  const getAllConversions = catchAsync(async () => {
    let userId = JSON.parse(localStorage.getItem('user'))?._id;
    let res = await api.get(`/conversion/all-notes/${userId}`);
    // console.log(res.data)
    setConversions(res.data.getAllConversion);
    if (!userDetails?.defaultAdded) {
      api.get('/user/userDetails').then(async (res2) => {
        localStorage.setItem('user', JSON.stringify(res2?.data));
        setUserDetails(res2?.data);
      });
    }
    setLoadingTemplate(false);
  });
  const handleNewConversionTemplate = () => {
    // Retrieve query parameters from localStorage
    const storedQueryParams = localStorage.getItem('queryParams');
    console.log('Stored Query Params:', storedQueryParams);
    if (storedQueryParams) {
      handleSubmit?.({});
    }
  };

  const [first, setfirst] = useState(true);
  useEffect(() => {
    handleButtonClickget();
    getAllConversions();
    setListItems('');
    setTimeout(() => {
      setRun(true);
    }, '1000');
    if (sessionStorage.getItem('new-conversion') == 'true') {
      sessionStorage.setItem('new-conversion', false);
      handleShow();
    }
  }, []);
  useEffect(() => {
    if (conversions && runOnce) {
      setRunOnce(false);
      handleNewConversionTemplate();
    }
  }, [conversions]);

  useLayoutEffect(() => {
    if (location?.pathname === '/logout') {
      Logout();
    }
  }, [location]);

  useEffect(() => {
    if (conversions?.length === 0 && userDetails?.defaultAdded) {
      handleShow();
    }
  }, [conversions, userDetails]);

  useEffect(() => {
    if (param !== null && conversions?.length > 0 && firstCheckLocation) {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.get('showPlans')) {
        setPriceModalShow(true);
      }
      const find = conversions?.find((val) => val?._id === param);

      if (find) {
        setListItems(param);
      } else if (find === undefined && param !== '') {
        setListItems('noPer');
      } else if (
        find === undefined &&
        param === null &&
        conversions?.length > 0
      ) {
        setListItems(conversions[0]._id);
      }
    }
    // else if (param !== null && conversions?.length === 0) {
    //   setListItems("noPer");
    else if (param === null && conversions?.length > 0 && first) {
      setListItems(conversions[0]._id);
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.get('showPlans')) {
        setPriceModalShow(true);
      }
      queryParams.delete('id');
      queryParams.append('id', conversions[0]._id);
      setfirst(false);
      // if (location?.pathname !== '/profile') {
      //   navigate('/?' + queryParams.toString());
      // }
    }
  }, [param, conversions, firstCheckLocation]);

  useEffect(() => {
    if (fetchConversions) {
      getAllConversions();
      setFetchConversions(false);
    }
  }, [fetchConversions]);

  const deleteConversions = catchAsync(async (id) => {
    let res = await api.delete(`/conversion/${id}`);
    let currCons = JSON.parse(localStorage.getItem('currentConverstion'));
    if (currCons === id) {
      localStorage.removeItem('currentConverstion');
      setListItems(null);
    }
    const data = conversions.filter((elem) => {
      return elem._id !== id;
    });
    setConversions(data);
    if (data?.length === 0) {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete('id');
      navigate('/?' + queryParams.toString());
    } else {
      handleButtonClick(conversions[0]._id);
      setListItems(conversions[0]._id);
    }
    setFirstCheckLocation(false);
  });

  const Logout = () => {
    signOut();
    navigate('/signin');
  };

  const email = JSON.parse(localStorage.getItem('user'))?.email;
  const ellipsisRef = useRef(null);

  const handleClickOutside = (event) => {
    if (event.target.className.baseVal !== 'dotsIcon') {
      setDropDownValue(false);
    }
  };

  const updatePosition = () => {
    setDropDownValue(null);
  };

  useEffect(() => {
    if (notesRef.current)
      notesRef.current.addEventListener('scroll', updatePosition);
    return () => {
      if (notesRef.current)
        notesRef.current.removeEventListener('scroll', updatePosition);
    };
  }, [notesRef]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const { userPlan: subscriptionPlan } = useContext(PlansContext);

  const [emailSubscriptions, setEmailSubscriptions] = useState([]);
  const fetchEmailSubscriptions = async (id) => {
    let res = await api.get(`/conversion/subscription/${id}`);
    setEmailSubscriptions(res.data?.data?.emailSubscription);
  };

  const toggleEmailSubscription = async (id) => {
    let res = await api.patch(`/conversion/subscribeEmail/${id}`);
    setEmailSubscriptions(res.data?.data?.emailSubscription);
  };

  function extractDateFromString(name) {
    // Define a regular expression pattern to match the date format
    const dateRegex = /\b(\d{4}-\d{2}-\d{2} \d{2}:\d{2})\b/;
    // Use the match method to find the date in the string
    const matchResult = name?.match(dateRegex);
    // Check if a match is found
    if (matchResult && matchResult[1]) {
      // Parse the input date string using Moment.js
      const inputMoment = moment(
        new Date(matchResult[1] + ' UTC')?.toLocaleString(),
        'M/D/YYYY, h:mm:ss A',
      );

      // Format the date in the desired custom format
      const formattedDate = inputMoment.format('YYYY-MM-DD HH:mm');
      return `${name?.split(matchResult[1])?.[0]} ${formattedDate}`;
    } else {
      // Return null if no date is found
      return name;
    }
  }

  return (
    <>
      <Drawer
        open={width > 722 ? true : openSideBar === true ? true : false}
        enableOverlay={width <= 722 ? true : false}
        onClose={toggleDrawer}
        direction="left"
        className="sidebarMain"
        style={{
          overflowY: height < 312 ? 'scroll' : 'auto',
        }}
      >
        {/* <Joyride
          continuous
          callback={onJoyrideCallback}
          //  completeCallback={handleJoyrideEnd}
          run={run}
          steps={steps}
          hideCloseButton
          // scrollToFirstStep
          showSkipButton
          showProgress
        /> */}
        {/* {openSideBar ? (
          <img
            src={close}
            alt="close"
            className="closeImg"
            onClick={() => setOpenSideBar(false)}
          />
        ) : null} */}

        <div className="sidebar_content">
            <div>
          <div className="d-flex flex-column align-items-center mt-2">
            <a href="https://www.datatera.ai/" target="_blank" className="">
              <img src={logo} width={30}  alt="" />
            </a>
            {width > 722 ? (
              <div className="emailLabel">
                <u>{email}</u>
              </div>
            ) : (
              <div className="emailLabel-sm d-flex ">
                <AiOutlineArrowLeft
                  className="arrow_icon me-2"
                  onClick={() => setOpenSideBar(false)}
                />
                <u>{email}</u>
              </div>
            )}
          </div>

          {/* <p className="view-profile-button" onClick={()=>navigate('/profile')}>View Profile</p> */}

          <div
            id="newstepone"
            className="new_conversion_main"
            onClick={handleShow}
          >
            {/* <img src={plus_icon} alt="plus_icon" /> */}
            <span className="new_conversion_div">New transformation</span>
          </div>
          </div>
          <div className="notes_logout_main">
            {loadingTemplate ? (
              <div className="d-flex h-100 justify-content-center align-items-center">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              <div
                ref={notesRef}
                className="notes"
                style={{
                  height: '100%',
                  maxHeight:
                    height > 630
                      ? '100%'
                      : height < 681 && height > 639
                      ? '90%'
                      : height < 639 && height > 589
                      ? '80%'
                      : height < 589 && height > 461
                      ? '70%'
                      : height < 461 && height > 372
                      ? '60%'
                      : '50%',
                }}
              >
                {!loadingTemplate &&
                  conversions?.map((elem, index) => {
                    var trimmedString = elem.name.substr(0, 16);
                    return (
                      <div
                        className={
                          list === elem?._id ? 'List selectedList' : 'List'
                        }
                        id={`sidebar${index}`}
                        key={elem._id}
                        style={{ position: 'relative' }}
                        onClick={() => {
                          setListItems(elem?._id);
                          handleButtonClick(elem?._id);
                          fetchEmailSubscriptions(elem?._id);
                        }}
                      >
                        {dropDownValue === elem?._id ? (
                          <div
                            className="dropdown2 shadow"
                            style={{
                                top: `${dropdownPosition.top}px`,
                            }}
                          >
                            <div
                              className="d-flex justify-content-between elements"
                              onClick={() => openConversionModal(elem)}
                            >
                              <span className="conversion_title">
                                Edit name and description
                              </span>
                              <AiOutlineEdit className="edit_icon" />
                            </div>
                            <>
                              <div
                                className={`d-flex justify-content-between elements ${
                                  dataPresentCheck === 'data present'
                                    ? ''
                                    : 'disable'
                                }`}
                                onClick={async () => {
                                  if (dataPresentCheck === 'data present') {
                                    let userId = JSON.parse(
                                      localStorage.getItem('user'),
                                    )?._id;
                                    let res2 = await api.get(
                                      `/conversion/getData/${elem?._id}`,
                                    );
                                    const newArray =
                                      res2?.data?.data[0]?.tableHeaders?.slice(
                                        2,
                                      );
                                    const data2 = res2?.data?.data;
                                    if (data2?.length) {
                                      data2[0].tableHeaders = newArray;

                                      data2[0].tableData.forEach((val, i) => {
                                        const newArray2 = val.slice(2);
                                        data2[0].tableData[i] = newArray2;
                                      });
                                      data2[0].tableData.unshift(newArray);
                                      let res = await api.post('/conversion', {
                                        user: userId,
                                        name: `Copy - ${elem?.name}`,
                                        description: elem?.description
                                      });

                                      let values = {
                                        user: userId,
                                        data: data2,
                                        csvFileName: res2?.data?.csvFileName,
                                        csvFileSize: res2?.data?.csvFileSize,
                                        conversion:
                                          res?.data?.createConversion?._id,
                                        sheetDetailsWrite: { empty: '' },
                                      };

                                      try {
                                        api
                                          .post('/conversion/addData', values)
                                          .then((res) => {
                                            getAllConversions();
                                          });
                                      } catch (error) {
                                        console.log(error);
                                      }
                                    }
                                  }
                                }}
                              >
                                <span className="conversion_title ">
                                  Copy without data
                                </span>
                                <AiOutlineCopy className="edit_icon" />
                              </div>
                              <div
                                className={`d-flex justify-content-between elements
                              ${
                                dataPresentCheck === 'data present'
                                  ? ''
                                  : 'disable'
                              }
                              `}
                                onClick={async () => {
                                  if (dataPresentCheck === 'data present') {
                                    let userId = JSON.parse(
                                      localStorage.getItem('user'),
                                    )?._id;
                                    let res = await api.post('/conversion', {
                                      user: userId,
                                      name: `Copy - ${elem?.name}`,
                                      description: elem?.description,
                                    });

                                    let values = {
                                      conversionToCopyId: elem?._id,
                                      userId: userId,
                                      newConversionId:
                                        res?.data?.createConversion?._id,
                                    };

                                    try {
                                      api
                                        .post(
                                          '/conversion/addDataByConversionId',
                                          values,
                                        )
                                        .then((res) => {
                                          getAllConversions();
                                        });
                                    } catch (error) {
                                      console.log(error);
                                    }
                                  }
                                }}
                              >
                                <span className="conversion_title ">
                                  Copy with data
                                </span>
                                <AiOutlineCopy className="edit_icon" />
                              </div>
                              <div
                                className={`d-flex justify-content-between elements
                            ${
                              dataPresentCheck === 'data present'
                                ? ''
                                : 'disable'
                            }
                            `}
                                onClick={() =>
                                  toggleEmailSubscription(elem._id)
                                }
                              >
                                {emailSubscriptions ? (
                                  <>
                                    <span className="conversion_title text-danger">
                                      Unsubscribe
                                    </span>
                                    <HiOutlineBellSlash className="edit_icon text-danger" />
                                  </>
                                ) : (
                                  <>
                                    <span className="conversion_title text-success">
                                      Subscribe
                                    </span>
                                    <HiOutlineBellAlert className="edit_icon text-success" />
                                  </>
                                )}
                              </div>
                            </>
                            <div
                              className="d-flex justify-content-between elements mt-2"
                              onClick={() => deleteConversions(elem._id)}
                            >
                              <span className="conversion_title text-danger">
                                Delete
                              </span>
                              <AiOutlineDelete className="edit_icon text-danger" />
                            </div>
                          </div>
                        ) : null}
                        <div className="notes__name_main">
                          <div>
                            <img
                              src={Chat_icon}
                              alt="Chat_icon"
                              className="notes_img"
                            />
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  {extractDateFromString(elem.name)}
                                </Tooltip>
                              }
                            >
                              <span className="conversion_title">
                                {trimmedString >= 17
                                  ? `${trimmedString}...`
                                  : trimmedString}
                              </span>
                            </OverlayTrigger>
                          </div>
                          <div>
                            {index + 1 !== conversions?.length && (
                              <AiOutlineArrowDown
                                onClick={async () => {
                                  setParams(conversions[index]?._id);
                                  const body = {
                                    id1: conversions[index]?._id,
                                    sequence_value1:
                                      conversions[index]?.sequence_value,
                                    id2: conversions[index + 1]?._id,
                                    sequence_value2:
                                      conversions[index + 1]?.sequence_value,
                                  };
                                  setLoadingTemplate(true);
                                  await api.post(
                                    '/conversion/changeOrderOfConversion',
                                    body,
                                  );
                                  getAllConversions();
                                }}
                              />
                            )}
                            {index !== 0 && (
                              <AiOutlineArrowUp
                                onClick={async () => {
                                  setParams(conversions[index]?._id);

                                  const body = {
                                    id1: conversions[index]?._id,
                                    sequence_value1:
                                      conversions[index]?.sequence_value,
                                    id2: conversions[index - 1]?._id,
                                    sequence_value2:
                                      conversions[index - 1]?.sequence_value,
                                  };
                                  setLoadingTemplate(true);

                                  await api.post(
                                    '/conversion/changeOrderOfConversion',
                                    body,
                                  );
                                  getAllConversions();
                                }}
                              />
                            )}

                            <AiOutlineEllipsis
                              ref={ellipsisRef}
                              onClick={async (e) => {
                                let res = await api.get(
                                  `/conversion/checkDataExist/${elem._id}`,
                                );
                                const position = e.target.getBoundingClientRect()
                                setDropdownPosition(position)
                                setDataPresentCheck(res?.data?.data);
                                setDropDownValue(
                                  dropDownValue === elem._id ? null : elem._id,
                                );
                              }}
                              style={{
                                rotate: '90deg',
                              }}
                              color={dropDownValue === elem._id && 'green'}
                              className="dotsIcon"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
            <div className="mt-auto section pb-1">
              <hr />
              <div className="sidebar_btns_main">
                <div
                  className="updates_faq_div mt-2 mb-1"
                  onClick={handlePriceModalShow}
                >
                  <img
                    src={subscription_icon}
                    alt="update-subscription"
                    className="update_icon"
                  />
                  
                  <span>
                    {subscriptionPlan?.[subscriptionPlan?.length - 1]?.name ===
                    'FREE'
                      ? 'Upgrade to PLUS'
                      : 'Subscription'}
                  </span>
                </div>

                {/* <div className="updates_faq_div">
                  <img
                    src={update_icon}
                    alt="update-icon"
                    className="update_icon"
                  />
                  <span>Updates & FAQ</span>
                </div> */}
                <div
                  className="updates_faq_div mt-2 mb-1"
                  onClick={() => navigate('/profile')}
                >
                  <img
                    src={upgrade_icon}
                    alt="update-profile"
                    className="update_icon"
                  />
                  <span>Profile</span>
                </div>

                <div
                  className="updates_faq_div mt-2 mb-1"
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate('/integrations');
                  }}
                >
                  <img
                    src={updates_icon}
                    alt="integrations"
                    className="update_icon"
                  />
                  {/* <span hidden>Integrations</span> */}
                  <span>Integrations</span>
                </div>

                <div className="updates_faq_div mb-1 mt-2" onClick={Logout}>
                  <img src={logout_icon} alt="logout" className="update_icon" />
                  <span>Logout</span>
                </div>
              </div>
              <div
                className="d-flex mt-2"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.open(
                    'https://chrome.google.com/webstore/detail/datateraai/eblmdnbklkapjchlcdllmhhcnkidnbio',
                    '_blank',
                  );
                }}
              >
                <img
                  src={extension_icon}
                  alt="extension_icon"
                  className="update_icon pt-1 flex-shrink-0"
                  width={20}
                  height={20}
                />
                <p className="m-0">Install Chrome Browser Extension</p>
              </div>
            </div>
          </div>
        </div>
      </Drawer>

      {/* Modal For Pricing */}
      <Modal
        show={showPriceModal}
        onHide={handlePriceModalClose}
        centered
        className="plan_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="conversionTitle">Your Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body className="plan_modal_body">
          <div className="prcing">
            <div className="Pricing_modal">
              {/* <div className="plan"> Your plan </div> */}
              <div className="call_component">
                {plans?.map((plan) => (
                  <PricingModal
                    setPriceModalShow={setPriceModalShow}
                    key={plan.id}
                    userPlan={userPlan}
                    plan={plan}
                  />
                ))}
              </div>
              <div className="contact_info">
                <div className="contact">Contact Us:</div>
                <div
                  className="contact_ref"
                  // onClick={() => handleMailLinkClick()}
                >
                  <a href="mailto:contacts@datatera.ai">contacts@datatera.ai</a>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="conversionTitle">
            {updateConversion ? 'Edit Transform' : 'Add Transform'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={schema}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, resetForm);
            }}
            enableReinitialize
            initialValues={{
              name: updateConversion?.name,
              description: updateConversion?.description,
            }}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit} className="form">
                <Form.Group controlId="name" className="mb-3">
                  <Form.Label className="conversionTitle">Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Transformation Name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.name && !formik.errors.name}
                    isInvalid={formik.touched.name && formik.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="description" className="mb-3">
                  <Form.Label className="conversionTitle">
                    Description
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Enter Transformation Description"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // isValid={
                    //   formik.touched.description && !formik.errors.description
                    // }
                    // isInvalid={
                    //   formik.touched.description && formik.errors.description
                    // }
                  />
                  {/* <Form.Control.Feedback type="invalid">
                    {formik.errors.description}
                  </Form.Control.Feedback> */}
                </Form.Group>
                <Button variant="primary" type="submit" className="submitBtn">
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {selectConversion ? (
        <Modal
          show={selectConversion}
          onHide={() => {
            setSelectConversion(false);
          }}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="conversionTitle">
              {'Select Conversion to add link'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Select
              options={[
                {
                  value: '',
                  label: 'Select',
                },
                ...conversionToList?.map((cL) => ({
                  value: cL?._id,
                  label: cL?.name,
                })),
              ]}
              onChange={(e) => {
                setSelectedConversion(e);
              }}
              values={selectedConversion}
              placeholder="Select a conversion"
            />
            <Button
              variant="primary"
              type="submit"
              className="submitBtn mt-3"
              disabled={!selectedConversion?.[0]?.value || loadingTemplate}
              onClick={async () => {
                setLoadingTemplate(true);
                const file = new File([processUrlProp], 'text.txt', {
                  type: 'text/plain',
                });
                await handleUploadFile(
                  file,
                  '0',
                  null,
                  '1',
                  true,
                  true,
                  selectedConversion?.[0]?.value,
                  true,
                  true,
                );
                addTempToConversion(
                  conversionToList?.find(
                    (cTL) => cTL?._id === selectedConversion?.[0]?.value,
                  ),
                  selectedConversion?.[0]?.value,
                );
                setLoadingTemplate(false);
              }}
            >
              Submit
            </Button>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
};

export default Sidebar;
